import * as React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import Iframe from 'react-iframe'

const GQLPlayground = () => (
  <Card>
    <Title title="GraphQL Query" />
    <CardContent>
      <Iframe url="/graphql.html"
              width="100%"
              height="900px"
              id="graphQL"
              className="graphQL"
              display="initial"
              position="relative"/>
    </CardContent>
  </Card>
);

export default GQLPlayground;
