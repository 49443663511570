import React, { Fragment } from 'react';
import { BooleanInput, TextInput, NumberInput } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

// Styled Components.
import { Group, Heading } from './common/styles/data-groups';

const DataGuesser = (...props) => {
  const source = props[0].source;

  const elements = { data: [] };
  const schema = JSON.parse(window.localStorage.getItem('schema'));

  // Switch cases.
  const switchCases = (i, data, groupName) => {
    let keyValue;
    let group = 'data';

    if (groupName) {
      keyValue = `${groupName}-${i}`;
      group = groupName;
    } else {
      keyValue = i;
    }

    const source_path = group === 'data' ? 'data.' + i : 'data.' + group + '.' + i;

    switch (schema[source][i]) {
      case 'Int':
        elements[group].push(
          <NumberInput key={keyValue} label={i} source={source_path} variant="outlined" />
        );
        break;

      case 'IntLong':
        elements[group].push(
          <NumberInput key={keyValue} label={i} source={source_path} variant="outlined" />
        );
        break;

      case 'Text':
        elements[group].push(<RichTextInput key={keyValue} label={i} source={source_path} />);
        break;

      case 'Boolean':
        elements[group].unshift(
          <BooleanInput key={keyValue} label={i} source={source_path} variant="outlined" />
        );
        break;

      default:
        elements[group].push(
          <TextInput key={keyValue} label={i} source={source_path} variant="outlined" />
        );
        break;
    }
  };

  // Loop through data.
  for (const i in schema[source]) {
    if (typeof schema[source][i] === 'string') {
      switchCases(i, schema);
    } else if (typeof schema[source][i] === 'object') {
      elements[i] = [];
      const nestedObject = schema[source][i];
      const nestedObjectName = i;

      // Loop through nested objects.
      for (const i in nestedObject) {
        if (typeof nestedObject[i] === 'string') {
          switchCases(i, nestedObject, nestedObjectName);
        }
      }
    }
  }

  // Iterate over object and return components within parent groups.
  let content = [];

  Object.keys(elements).forEach(key => {
    let groupFields = elements[key].map((field, index) => {
      return <Fragment key={index}>{field}</Fragment>;
    });
    content.push(
      <Fragment key={key}>
        <Heading>{key}</Heading>
        <Group>{groupFields}</Group>
      </Fragment>
    );
  });

  // Final output.
  return <Fragment>{content}</Fragment>;
};

export default DataGuesser;
