import gql from 'graphql-tag';
import buildFieldList from './buildFieldList';

const dot = require('dot-object');

const myBuildQuery = (introspection, props) => (fetchType, resource, params) => {
  let fields = buildFieldList(introspection, resource, fetchType, params);

  if (resource === 'users') {
    switch (fetchType) {
      case 'GET_ONE':
        return {
          query: gql`
            query Command($id: String!) {
                user(UID: $id) {
                    ${fields}
                }
            }`,
          variables: params,
          parseResponse: response => {
            return {
              data: response.data.user
            };
          }
        };

      case 'GET_LIST':
        let filters = '(';
        const page = params.pagination.page;
        if (page === 1) {
          localStorage.removeItem('nextCursor');
        }
        const perPage = params.pagination.perPage;
        const nextCursor = localStorage.getItem('nextCursor');
        const selected_filters = dot.dot(params.filter);
        filters += 'limit: ' + params.pagination.perPage;
        let use_sort = true;
        if (selected_filters) {
          filters += ',filters: [';
          for (let i in selected_filters) {
            use_sort = false;
            const safe_field = i.replace(/\=/g, '.');

            filters +=
              '{field: "' +
              safe_field +
              '", value: "' +
              selected_filters[i] +
              '", operation: "=="}';
          }

          filters += ']';
        }

        if (use_sort && params.sort.field) {
          filters +=
            ', sortBy: "' +
            params.sort.field +
            '", sortDirection: "' +
            params.sort.order.toLowerCase() +
            '"';
        }

        if (nextCursor) {
          filters += ', cursorId: "' + nextCursor + '"';
        }

        filters += ')';

        let query = gql`
            query listUsers {
                users ${filters}{
                    ${fields}
                }
            }`;

        return {
          query: query,
          variables: {},
          parseResponse: response => {
            let users = [];
            let nextCursorId = '';
            for (let user of response.data.users) {
              users.push(user);
              nextCursorId = user.cursorId;
            }
            let total = (page - 1) * perPage + response.data.users.length;
            localStorage.setItem('prevCursor', localStorage.getItem('nextCursor'));
            if (users.length < perPage) {
              nextCursorId = '';
            }
            localStorage.setItem('nextCursor', nextCursorId);
            return {
              data: users,
              total: total,
              nextCursor: nextCursorId
            };
          }
        };

      case 'UPDATE':
        return {
          query: gql`
            mutation UpdateUser($id: String!, $user: JSONObject) {
              updateUser(UID: $id, user: $user) {
                success,
                message,
                user {
                  ${fields}
                },
              }
            }`,
          variables: { id: params.id, user: dot.dot(params.data) },
          parseResponse: response => {
            return {
              data: response.data.updateUser.user
            };
          }
        };

      case 'CREATE':
        let user_data = dot.dot(params.data);
        user_data.UID = user_data.id;
        return {
          query: gql`
            mutation CreateUser($user: JSONObject) {
              createUser(user: $user) {
                success,
                message,
                user {
                  ${fields}
                },
              }
            }`,
          variables: { user: user_data },
          parseResponse: response => {
            if (!response.data.createUser.success) {
              return new Error(response.data.createUser.message);
            }
            return {
              data: response.data.createUser.user
            };
          }
        };

      case 'DELETE':
        return {
          query: gql`
            mutation DeleteUser($id: String!) {
              deleteUser(UID: $id) {
                success
                message
              }
            }
          `,
          variables: { id: params.id },
          parseResponse: response => {
            return {
              data: params.previousData
            };
          }
        };
      default:
        return {
          data: null
        };
    }
  }
};

export default myBuildQuery;
