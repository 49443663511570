
function getFields(type, types) {
  let fields = "";
  const type_object = types.find(
    r => r.name === type
  );

  if (type_object) {
    for (const field of type_object.fields) {
      if (field.name === 'UID') {
        field.name = 'id: UID';
      }
      if (field.type.kind === 'SCALAR') {
        fields += '\n' + field.name;
      }
      if (field.type.kind === 'OBJECT') {
        fields += '\n' + field.name + '{\n' + getFields(field.type.name, types) + '\n}';
      }
      if (field.type.kind === 'LIST') {
        if (field.type.ofType.kind === 'SCALAR') {
          fields += '\n' + field.name;
        }
      }
    }
  }

  return fields;
}

function getSchema(type, types) {
  let fields = {};
  const type_object = types.find(
    r => r.name === type
  );

  if (type_object) {
    for (const field of type_object.fields) {
      if (field.type.kind === 'SCALAR') {
        fields[field.name] = field.type.name;
      }
      if (field.type.kind === 'OBJECT') {
        fields[field.name] = getSchema(field.type.name, types);
      }
      if (field.type.kind === 'LIST') {

        if (field.type.ofType.kind === 'SCALAR') {
          fields[field.name] = [field.type.ofType.name];
        }
      }
    }
  }

  return fields;
}

const buildFieldList = (introspection, resource, fetchType) => {
  // TODO Do This somewhere better.
  const schema = getSchema('User', introspection.types);
  window.localStorage.setItem('schema', JSON.stringify(schema));
  return getFields('User', introspection.types);
};

export default buildFieldList;
