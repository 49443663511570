import React, { Fragment } from 'react';
import {
  List,
  TopToolbar,
  BooleanInput,
  DateTimeInput,
  NumberInput,
  Button,
  ShowButton,
  Filter,
  Datagrid,
  TabbedForm,
  FormTab,
  Create,
  Edit,
  SimpleForm,
  TextField,
  TextInput,
  EmailField
} from 'react-admin';
import DataGuesser from '../../dataGuesser';
import UserAvatar from '../../components/UserAvatar';
import CursorPagination from '../../components/CursorPagination';
import RichTextInput from 'ra-input-rich-text';
import ProfilePictureUpload from '../../components/ProfilePictureUpload';

// Styled Components.
import { Group } from '../../common/styles/data-groups';

export const UserList = props => (
  <List
    filters={<UserFilter />}
    pagination={<CursorPagination />}
    sort={{ field: 'lastUpdated', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <UserAvatar />
      <EmailField label="Email" source="email" />
      <TextField label="Created" source="created" />
      <TextField label="Last Updated" source="lastUpdated" />
      <TextField label="Customer ID" source="data.customerID" />
      <TextField label="Consultant ID" source="data.consultantID" />
    </Datagrid>
  </List>
);

const UserFilter = props => {
  let filters = [];
  let something = window.localStorage.getItem('schema');
  if (something !== 'undefined') {
    const schema = JSON.parse(something);

    filters.push(<TextInput key="UID" label="UID" source="UID" alwaysOn />);
    filters.push(<TextInput key="firstName" label="First Name" source="firstName" alwaysOn />);
    filters.push(<TextInput key="lastName" label="Last Name" source="lastName" alwaysOn />);
    filters.push(<TextInput key="email" label="Email" source="email" alwaysOn />);

    if (schema) {
      for (let i in schema.data) {
        if (typeof schema.data[i] === 'string') {
          const source_name = 'data=' + i;
          filters.push(<TextInput key={i} label={i} source={source_name}/>);
        }
      }
    }
  }

  return (
    <Filter {...props} variant="outlined">
      {filters}
    </Filter>
  );
};

function resetPasswordAction() {}

const UserEditActions = ({ basePath, data, resource }) => (
  <TopToolbar>

  </TopToolbar>
);

const UserEditFormLayout = (props) => (
  <Fragment>
    <Group>
      <TextInput source="id" variant="outlined" />
      <DateTimeInput source="created" variant="outlined" />
    </Group>
    <Group className="profile-pic" {...props}>
      <ProfilePictureUpload source="photoURL" {...props} />
    </Group>
    <Group>
      <TextInput source="firstName" variant="outlined" />
      <TextInput source="lastName" variant="outlined" />
    </Group>
    <Group>
      <TextInput source="email" variant="outlined" />
      <TextInput source="phoneNumber" variant="outlined" />
      <TextInput source="gender" variant="outlined" />
    </Group>
    <Group>
      <TextInput source="profileURL" variant="outlined" />
      <TextInput source="photoURL" variant="outlined" />
      <TextInput source="thumbnailURL" variant="outlined" />
    </Group>
    <Group>
      <RichTextInput source="bio" />
    </Group>

    <Group>
      <NumberInput source="birthDay" variant="outlined" />
      <NumberInput source="birthMonth" variant="outlined" />
      <NumberInput source="birthYear" variant="outlined" />
    </Group>

    <Group>
      <TextInput source="address" variant="outlined" />
      <TextInput source="city" variant="outlined" />
      <TextInput source="state" variant="outlined" />
      <TextInput source="zip" variant="outlined" />
      <TextInput source="country" variant="outlined" />
    </Group>
    <Group>
      <BooleanInput source="isActive" />
      <BooleanInput source="isVerified" />
      <BooleanInput source="isRegistered" variant="outlined" />
    </Group>
    <Group>
      <TextInput source="locale" variant="outlined" />
      <TextInput source="languages" variant="outlined" />
      <TextInput source="timezone" variant="outlined" />
    </Group>
  </Fragment>
);

export const UserEdit = props => {
  return (
    <Edit undoable={false} actions={<UserEditActions />} {...props}>
      <TabbedForm>
        <FormTab label="Profile">
          <UserEditFormLayout />
        </FormTab>

        <FormTab label="Data">
          <DataGuesser source="data" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const UserCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <Group>
        <TextInput source="id" variant="outlined" />
        <TextInput source="email" variant="outlined" />
      </Group>
      <Group>
        <TextInput source="firstName" variant="outlined" />
        <TextInput source="lastName" variant="outlined" />
      </Group>
    </SimpleForm>
  </Create>
);
