import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import amber from '@material-ui/core/colors/amber';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import lime from '@material-ui/core/colors/lime';
import lightGreen from '@material-ui/core/colors/lightGreen';
import grey from '@material-ui/core/colors/grey';
const theme = createMuiTheme();

const membersifyTheme = createMuiTheme({
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['Roboto', 'Arial', 'sans-serif'].join(',')
  },
  palette: {
    primary: {
      main: blue[600]
    },
    secondary: {
      main: amber[700]
    },
    error: {
      main: red[600]
    },
    warning: {
      main: orange[600]
    },
    info: {
      main: lime[500]
    },
    success: {
      main: lightGreen[700]
    },
    text: {
      primary: grey[900],
      secondary: grey[800],
      disabled: grey[500]
    }
  },
  overrides: {
    MuiDrawer: {
      root: {
        background: `linear-gradient(to bottom right, ${blue[600]}, ${blue[800]})`
      },
      paper: {
        backgroundColor: 'transparent'
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0,0,0,.25)'
      }
    },
    MuiTableCell: {
      root: {
        '& .MuiTypography-root': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        },
        '& a': {
          color: blue[600]
        },
        '& a:hover, a:active, a:visited': {
          color: blue[800]
        }
      }
    },
    RaLayout: {
      appFrame: {
        boxShadow: '0 0 300px rgba(0, 0, 0, .25)'
      },
      root: {
        backgroundColor: '#e6e6e6',
        minWidth: 'none'
      },
      content: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          padding: '1.5rem 1.5rem 1.5rem',
          overflow: 'hidden'
        },
        [theme.breakpoints.down('xs')]: {
          '& .MuiPaper-rounded': {
            borderRadius: 0
          },
          '& .MuiPaper-root': {
            boxShadow: 'none'
          }
        }
      }
    },
    RaSidebar: {
      drawerPaper: {
        backgroundColor: '#fff',

        [theme.breakpoints.up('sm')]: {
          backgroundColor: 'transparent'
        }
      }
    },
    RaMenuItemLink: {
      icon: {
        color: grey[500],
        [theme.breakpoints.up('sm')]: {
          color: blue[300]
        }
      },
      root: {
        color: grey[500],
        [theme.breakpoints.up('sm')]: {
          color: blue[100]
        }
      },
      active: {
        color: blue[600],

        [theme.breakpoints.up('sm')]: {
          color: '#fff'
        },

        '& .MuiListItemIcon-root': {
          color: blue[600],

          [theme.breakpoints.up('sm')]: {
            color: '#fff'
          }
        }
      }
    },
    RaList: {
      main: {
        overflow: 'hidden'
      },
      content: {
        maxwidth: '100%',
        overflow: 'auto'
      }
    },
    RaDatagrid: {
      headerCell: {
        backgroundColor: grey[100],
        fontWeight: '700',
        textTransform: 'uppercase',
        fontSize: '.8em'
      }
    },
    RaFilterForm: {
      form: {
        alignItems: 'center',
        marginTop: 0
      }
    },
    RaFilterFormInput: {
      body: {
        alignItems: 'center'
      },
      hideButton: {
        padding: 8
      }
    }
  }
});

export default membersifyTheme;
