import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';

// Firebase settings
const firebase = require("firebase");
// Required for side-effects
require("firebase/firestore");

let getUrl = window.location;
let baseUrl = getUrl .protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];
if (baseUrl.includes('localhost') || baseUrl.includes('icsandbox')) {
  firebase.initializeApp({
    apiKey: "AIzaSyAOT4AgYIjK2YAH0JsaiT09zXOXviIbufY",
    authDomain: "identity-cloud-development.firebaseapp.com",
    databaseURL: "https://identity-cloud-development.firebaseio.com",
    projectId: "identity-cloud-development",
    storageBucket: "identity-cloud-development.appspot.com",
    messagingSenderId: "77300373788",
    appId: "1:77300373788:web:ae6ac074e8bad4038942d9"
  });
}
else {
  firebase.initializeApp({
    apiKey: "AIzaSyDhfe1u-3vhHlOvx_B--L7NlZHtYrWef_g",
    authDomain: "membersify-identity-cloud.firebaseapp.com",
    databaseURL: "https://membersify-identity-cloud.firebaseio.com",
    projectId: "membersify-identity-cloud",
    storageBucket: "membersify-identity-cloud.appspot.com",
    messagingSenderId: "758487598534",
    appId: "1:758487598534:web:1e7288c0e21af398acdec7",
    measurementId: "G-VHL8L90G4K"
  });
}

firebase.initializeApp({
  apiKey: "AIzaSyA_b9q9LHBjyvGqZGuTSmzt09e21xi5aj0",
  authDomain: "ic-posh-production.firebaseapp.com",
  databaseURL: "https://ic-posh-production.firebaseio.com",
  projectId: "ic-posh-production",
  storageBucket: "ic-posh-production.appspot.com",
  messagingSenderId: "24920032595",
  appId: "1:24920032595:web:cbb0e64e9bd95bc1e18da6"
}, 'client');

/**
 * Ultra simple authentication provider
 *
 * @param {string} type Request type, e.g AUTH_LOGIN
 * @param {Object} params Request parameters. Includes login and pwd
 * @returns {Promise} the Promise for a data response
 */
export const firebaseAuthProvider = async (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    return firebase.auth()
      .signInWithEmailAndPassword(username, password)
      .then( (result) => {
        console.log('user logged in', result);
        localStorage.setItem('token', result.user.getIdToken());
        const idTokenResult = result.user.getIdTokenResult()
          .then((idTokenResult) => {
            // Once they login, we need to determine which sites they have
            // access to, and which role they have.
            const claims = idTokenResult.claims;

            // Select the first site as the default.
            localStorage.setItem('currentSite', Object.keys(claims.sites)[0]);

            window.localStorage.setItem('claims', JSON.stringify(idTokenResult.claims));
          })
          .catch((error) => {
            console.log('claims error', error);
          });
      })
      .catch( (error) => { throw new Error(error.message) } );
  }

  if (type === AUTH_LOGOUT) {
    console.log('logging out');
    localStorage.removeItem('token');
    localStorage.removeItem('currentSite');
    return firebase.auth().signOut()
      .catch( (error) => { throw new Error(error.message) } );
  }

  if (type === AUTH_CHECK) {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
  }

  if (type === AUTH_GET_PERMISSIONS) {
    const currentSite = localStorage.getItem('currentSite');
    const claims = JSON.parse(window.localStorage.getItem('claims'));
    return claims.sites[currentSite];
  }

  return Promise.resolve();
};
