// in src/App.js
import React from 'react';
import buildGraphQLProvider from 'ra-data-graphql';
import myBuildQuery from './dataProvider';
import customRoutes from './customRoutes';
import { Admin, Resource } from 'react-admin';
import { UserList, UserEdit, UserCreate } from './templates/users';
import UserIcon from '@material-ui/icons/Group';
import Menu from './Menu';
//import Dashboard from './Dashboard';
import { firebaseAuthProvider } from './authProvider';
import MyLayout from './MyLayout';
import nextTokenReducer from './reducers/nextTokenReducer';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';

// THEME
import membersifyTheme from './theme/membersifyTheme';
import GQLPlayground from "./pages/GQLPlayground";

const firebase = require('firebase');

const App = () => {
  const [dataProvider, setDataProvider] = React.useState(null);

  React.useEffect(() => {
    const onMount = async () => {
      let getUrl = window.location;
      let baseUrl = getUrl .protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];
      if (baseUrl.includes('localhost')) {
        baseUrl = 'https://icsandbox.membersify.com/';
      }
      const httpLink = createHttpLink({
        uri: baseUrl + 'api/v1/gql'
      });

      firebase.auth().onAuthStateChanged(async function(user) {
        const authLink = setContext(async (_, { headers }) => {
          const currentSite = localStorage.getItem('currentSite');
          if (user) {
            const token = await user.getIdToken();
            localStorage.setItem('token', token);
            return {
              headers: {
                ...headers,
                'X-Membersify-Site-Key': currentSite,
                'X-Membersify-Token': token
              }
            };
          }
          else {
            //localStorage.removeItem('token');
          }
        });

        const provider = await buildGraphQLProvider({
          buildQuery: myBuildQuery,
          clientOptions: {
            link: authLink.concat(httpLink)
          }
        });
        setDataProvider(() => provider);
      });
    };

    onMount();

    return () => {};
  }, []);

  if (!dataProvider) return <div>Loading...</div>;

  return (
    <Admin
      layout={MyLayout}
      authProvider={firebaseAuthProvider}
      customRoutes={customRoutes}
      menu={Menu}
      //dashboard={Dashboard}
      dataProvider={dataProvider}
      customReducers={{ nextToken: nextTokenReducer }}
      theme={membersifyTheme}
    >
      {permissions => [
        <Resource
          name="users"
          list={UserList}
          edit={permissions === 'admin' ? UserEdit : null}
          create={permissions === 'admin' ? UserCreate : null}
          icon={UserIcon}
        />
      ]}
    </Admin>
  );
};

export default App;
