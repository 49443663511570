import React, { useState } from 'react';

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
import {FormDataConsumer, TextInput} from "react-admin";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "firebase/storage";


// Firebase settings
const firebase = require("firebase");

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const ProfilePictureUpload = ({ record = {}, source }) => {
  let files_default = [];
  if (record[source]) {
    files_default.push({
      source: record[source],
      options: {
        type: "local"
      }
    });
  }
  const [files, setFiles] = useState(files_default);
  const [photoURL, setPhotoURL] = useState(record[source]);

  // We need to use the firebase associated with the selected site id.
  const storage = firebase.app('client').storage().ref();

  const classname = '';

  return (
    <div className={classname} style={{width: 200, height: 200}}>
      <FormDataConsumer style={{display: "none"}}>
        {({formData, dispatch, ...rest}) => {
          formData.photoURL=photoURL;
          return ('');
        }}
      </FormDataConsumer>
      <TextInput style={{display: "none"}} source={source} />
      <FilePond
        style={{height: 10}}
        files={files}
        onupdatefiles={fileItems => {
          // Set currently active file objects to this.state
          setFiles(fileItems.map(fileItem => fileItem.file));
        }}
        allowMultiple={false}
        maxFiles={1}
        labelIdle={`Drag & Drop your picture or <span class="filepond--label-action">Browse</span>`}
        imagePreviewHeight={200}
        imageCropAspectRatio={'1:1'}
        imageResizeTargetWidth={200}
        instantUpload={true}
        imageResizeTargetHeight={200}
        stylePanelLayout={'compact circle'}
        styleLoadIndicatorPosition={'center bottom'}
        styleProgressIndicatorPosition={'right bottom'}
        styleButtonRemoveItemPosition={'left bottom'}
        styleButtonProcessItemPosition={'right bottom'}
        server={{
          process: (
            _fieldName,
            file,
            _metadata,
            load,
            error,
            progress,
            _abort
          ) => {
            if (!file) {
              _abort();
            }
            const id = new Date().getTime();
            const task = storage.child("profile/" + record['id'] + '/' + id).put(file, {
              contentType: "image/jpeg"
            });

            task.on(
              firebase.storage.TaskEvent.STATE_CHANGED,
              snap => {
                progress(true, snap.bytesTransferred, snap.totalBytes);
              },
              err => {
                console.log("error: %o", err);
                error(err.message);
              },
              (obj) => {
                load(id);

                task.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                  setPhotoURL(downloadURL);
                });
              }
            );
          },
            fetch: (url, load, error, progress, abort) => {
              if (!url) {
                abort();
              }
              //url = "https://cors-anywhere.herokuapp.com/" + url;
              progress(true, 0, 1024);
              let xhr = new XMLHttpRequest();
              xhr.responseType = 'blob';
              xhr.onload = function() {
                load(xhr.response);
              };
              xhr.open('GET', url);
              xhr.send();
            },
            load: (url, load, error, progress, abort) => {
              if (!record[source]) {
                abort();
              }
              progress(true, 0, 1024);

              let xhr = new XMLHttpRequest();
              xhr.responseType = 'blob';
              xhr.onload = function() {
                load(xhr.response);
              };
              xhr.open('GET', "https://cors-anywhere.herokuapp.com/" + record[source]);
              xhr.send();
          }
        }}
    />
    </div>
  )
};

export default ProfilePictureUpload;
