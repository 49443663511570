import React from 'react';
import { AppBar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import logo from './logo.svg';

import Select from 'react-select';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  spacer: {
    flex: 1
  },
  logo: {
    display: 'block',
    marginBottom: '-3px',
    maxWidth: '120px'
  },
  appBar: {
    backgroundColor: '#fff',
    color: '#0b88ca'
  },
  siteSelector: {
    width: 300,
  }
});

const MyAppBar = props => {
  const classes = useStyles();

  // FIXME - Get the sites from the api.
  const allSites = {
    "r8zDRFcczg6jyQC2hwNqOHA2TYRUg56A": "Perfectly Posh - Staging",
    "HrEZK4qA4Y9IyXwYdbkzkqyLGfUsn1AF": "Perfectly Posh - Production",
  }
  const claims = JSON.parse(window.localStorage.getItem('claims'));

  const sites = {};

  for (let i in claims.sites) {
    sites[i] = allSites[i];
  }

  let options = [];
  
  for (let i in sites) {
    options.push({
      value: i,
      label: sites[i]
    });
  }

  const currentSite = localStorage.getItem('currentSite');

  function handleChange(selectedOption) {
    localStorage.setItem('currentSite', selectedOption.value);
    window.location.reload(false);
  }

  return (
    <AppBar className={classes.appBar} {...props}>
      <span className={classes.spacer}>
        <img className={classes.logo} src={logo} alt="Membersify" />
      </span>
      <Select
        className={classes.siteSelector}
        label="Site"
        placeholder="Site"
        value={{value: currentSite, label: sites[currentSite]}}
        onChange={handleChange}
        options={options}
      />
    </AppBar>
  );
};

export default MyAppBar;
