import React from 'react';
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
}));

const AvatarField = ({ record, size = '50' }) => {
  const classes = useStyles();

  return record ? (
    <div className={classes.root}>
      <Avatar
        src={`${record.thumbnailURL ? record.thumbnailURL : record.photoURL}`}
        style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
        className={classes.avatar}
      />
      {record.firstName} {record.lastName}
    </div>
  ) : null
};

export default AvatarField;
