import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';
const theme = createMuiTheme();

export const Group = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 1.25em;
  padding-left: 1em;
  padding-right: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.025);
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: calc(100% - 2em);

  .MuiFormControl-root {
    width: 100%;

    &.ra-rich-text-input {
      width: 100%;
    }
  }

  .MuiInputBase-root {
    background: #fff;
  }

  .ra-rich-text-input {
    .ql-editor {
      background: #fff;
      border-radius: 3px;
    }
  }

  &.profile-pic {
    text-align: center;
    padding-bottom: 1.5em;

    & > div {
      margin: 0 auto;
    }
  }

  .filepond--drip {
    background: rgba(0, 0, 0, 0.5);
  }

  .MuiFormGroup-root {
    width: 100%;
  }

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    .MuiFormControl-root {
      width: auto;
      margin-right: 1.5em;
    }

    &.profile-pic {
      text-align: left;

      & > div {
        margin: 0 1.5%;
      }
    }
  }

  @media (min-width: ${theme.breakpoints.values.md}px) {
    .MuiFormGroup-root {
      width: 45%;
      margin-right: 5%;
    }
  }

  @media (min-width: ${theme.breakpoints.values.lg}px) {
    .MuiFormGroup-root {
      width: 28.3333%;
      margin-right: 5%;
    }
  }
`;

export const Heading = styled.h4`
  margin: 1.5em 0 -0.5em 0;
  font-family: ${theme.typography.fontFamily};
`;
